import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';

import Loader from '../../../components/Bridgestone/Loader.jsx';

import { StoreContext } from '../../../components/Contexts/StoreContext';
import { UserContext } from '../../../components/Contexts/UserContext';
import RankingPositionVariation from '../../../components/UI/Ranking/RankingPositionVariation';
import PaginationControls from '../../../components/UI/Pagination/PaginationControls';
import Filters from '../../../components/Filters/Filters';
import DetailedAssessment from '../../../components/Ranking/DetailedAssessment';
import RankingService from '../../../services/RankingService';
import DetailedAssessmentBandag from '../../../components/Ranking/DetailedAssessmentBandag';
import { Button } from '@mui/material';
import FileSaver from 'file-saver';

const rowsPerPage = 20;

export default function Ranking({
  showFilters = true,
  showRegionButtons = true,
  showAllRows = false,
  containerClass = '',
  showDownloadButton = false
}) {
  const { token, user } = useContext(UserContext);
  const { store, storeCode, selectedCategory, consolidatedRegion } = useContext(StoreContext);

  const [regionSelected, setRegionSelected] = useState(1);

  const [ranking, setRanking] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!selectedCategory || !regionSelected) {
      return;
    }

    setLoading(true);

    // let regionId = (store && store.region && store.region.id) || 0;
    let regionId = 0;

    if (consolidatedRegion && consolidatedRegion.regionId) {
      regionId = consolidatedRegion.regionId;
    }

    RankingService.List(token, selectedCategory, regionSelected == 1 ? regionId : 0)
      .then((resp) => {
        if (showAllRows) {
          setRanking(resp.data.ranking);
        } else {
          setRanking(resp.data.ranking.slice(0, 10));
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storeCode, selectedCategory, regionSelected, consolidatedRegion]);

  const handleDownload = () => {
    setLoading(true);

    RankingService.DownloadRanking(token, selectedCategory)
      .then((resp) => {
        const filename = `Ranking_${selectedCategory}.xlsx`;
        const file = new File([resp.data], filename);

        FileSaver.saveAs(file);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const paginator = (items, currentPage) => {
    const page = currentPage || 1;
    const offset = (page - 1) * rowsPerPage;

    return items.slice(offset).slice(0, rowsPerPage);
  };

  return (
    <>
      {loading && <Loader />}

      {/* Page content */}
      <Container fluid className={containerClass}>
        {showFilters && <Filters />}

        <DetailedAssessment />

        {selectedCategory == 'BANDAG' && <DetailedAssessmentBandag />}

        {/* Table */}
        <Row>
          <div className="col mt-4">
            <Card className="shadow borderradius-default">
              <CardHeader className="border-0 borderradius-default">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      {' '}
                      Total de {ranking.length} lojas{' '}
                    </h6>
                    <h2 className="mb-0">Ranking das lojas {!showAllRows ? '- Top 10' : ''}</h2>
                  </div>

                  {showDownloadButton && (
                    <div className="col text-right">
                      <Button
                        type="button"
                        className="background-primary color-white borderradius-default"
                        size="small"
                        onClick={handleDownload}
                      >
                        Baixar pontos dos seus clientes
                      </Button>
                    </div>
                  )}

                  {/* {showRegionButtons && (
                    <div className="col text-right">
                      <Nav className="justify-content-end" pills>
                        <NavItem>
                          <NavLink
                            className={`${classnames('py-2 px-3', {
                              active: regionSelected === 1
                            })} borderradius-default`}
                            onClick={() => setRegionSelected(1)}
                            style={{ cursor: 'pointer' }}
                          >
                            <span className="d-none d-md-block">Regional</span>
                            <span className="d-md-none">R</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames('py-2 px-3', {
                              active: regionSelected === 2
                            })} borderradius-default`}
                            data-toggle="tab"
                            onClick={() => setRegionSelected(2)}
                            style={{ cursor: 'pointer' }}
                          >
                            <span className="d-none d-md-block">Nacional</span>
                            <span className="d-md-none">N</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  )} */}
                </Row>
              </CardHeader>

              {/* {user.profileId != 1 && (
                <div className="m-3 text-center">
                  <h3>Estamos em fase de auditoria. Em breve, divulgaremos os resultados.</h3>
                </div>
              )} */}

              {ranking.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Posição</th>
                      {user.profileId != 6 && (<th scope="col">Código</th>)}
                      <th scope="col">Loja</th>
                      <th scope="col" className="textalign-center">
                        Pontuação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginator(ranking, currentPage).map((rank, k) => {
                      return (
                        <tr key={k}>
                          <th scope="row">
                            <span className="mr-4">{`${rank.position.current || 0}° `}</span>
                            <RankingPositionVariation
                              before={rank.position.previous || 1}
                              now={rank.position.current || 0}
                            />
                          </th>
                          {user.profileId != 6 && (<td scope="row">{rank.client.code}</td>)}
                          <td>{rank.client.name}</td>
                          <td className="textalign-center">{rank.points}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

              {/* {!ranking.length && (
                <div className="m-3 text-center">
                  <h3>Aguarde o início do Programa de Excelência {new Date().getFullYear()}.</h3>
                </div>
              )} */}

              <CardFooter className="py-4 borderradius-default">
                <nav aria-label="...">
                  <PaginationControls
                    totalItems={ranking.length}
                    itemsPerPage={rowsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
